.Toastify__toast {
  @include border-radius($alert-border-radius);
  color: $white;
}

@each $color, $value in $theme-colors {
  .Toastify__toast--#{$color} {
    background: theme-color-level($value, $alert-color-scale);
  }
}
