.modal-content {
  .modal-header {

    button.close {
      color: $white;
      opacity: 0.9;
    }
  }

  .modal-footer {
    background: $input-group-addon-bg;

    .btn {
      box-shadow: none;
    }
  }
}
