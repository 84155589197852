@font-face {
    font-family: 'SfProDisplay';
    src: url("../../fonts/SFProDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'SfProDisplay';
  src: url("../../fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-underline.text-strikethrough {
  text-decoration: underline line-through;
}

.text-superscript,
.text-subscript {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.text-subscript {
  bottom: -0.25em;
}

.text-superscript {
  top: -0.5em;
}

.text-baseline {
  vertical-align: baseline;
}

a {
  text-decoration:underline;

  &:hover, &:focus {
    color:$orange;
  }
}