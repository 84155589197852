$blue: #44596c;
$lightBlue: #1ef3fd;

$gray-600: rgb(153, 153, 153);

$darkGray: #4a4949;
$gray: #d8d9d9;
$orange: rgb(247, 146, 99);
$red: #dc3545;

$primary: #000;
$secondary: $orange;
$light: $gray;
$dark: $darkGray;
$white: #ffffff;

$border-primary: $blue;

$input-placeholder: $blue;

$font-size-base: 14;
$input-color: $primary;
$input-border-color: $blue;
$input-border-radius: 0;
$btn-border-radius: 0;

$border-radius: 0;