.loading-container {
  font-size: 2rem;
  line-height: 1;
  white-space: nowrap;

  .spinner-grow,
  .spinner-border {
    vertical-align: top;
  }
}
