.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 5;

  .progress-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    .progress-label {
      text-align: center;
      margin-top: 5px;
      font-weight: 500;
      color: $primary;
    }

    .progress {
      width: 100%;
    }
  }
}
