.form-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-height: 1.3;
}

.form-notice {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
}

.inset-list {
  list-style: none;
  padding-left: 40px;
  line-height: 1.3;

  li {
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 18px solid rgb(247, 146, 99);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      position: absolute;
      left: -40px;
      top: 0;
    }
  }
}

.btn {
  font-weight: bold;
}

label.required:after {
  content: " *";
  color: $red;
}

.form-control {
  font-size: 16px;
}

.iam-confirmation {
  font-weight: 400;
  font-size: 18pt;
}

.iam-confirmation-thankyou {
  font-weight: 700;
  font-size: 48pt;
  font-family: "Neue Haas Grotesk";
  color: #FF624E;
}